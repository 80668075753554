//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterValueItemMixin from "./mixin/filterValueItemMixin";

export default {
    name: "CheckboxColors",
    props: {
        filtersList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    mixins: [filterValueItemMixin],
    computed: {
        itemHasImage() {
            return (item) => {
                return !!(item?.extra?.imageSrc || '');
            }
        },
        imageInfo() {
            return (item) => ({
                type: 'image',
                src: {
                    desktop: {
                        '1x': item.extra.imageSrc,
                        '2x': item.extra.imageSrc
                    },
                    tablet: {
                        '1x': item.extra.imageSrc,
                        '2x': item.extra.imageSrc
                    },
                    mobile: {
                        '1x': item.extra.imageSrc,
                        '2x': item.extra.imageSrc
                    },
                },
                title: item.title,
                alt: item.title,
            })
        }
    }
}
